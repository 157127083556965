.configurationContainer {
  width: 100%;
  overflow-x: hidden;

  .title {
    margin-bottom: 24px;
  }

  .localBusUnitsContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
