$header-height: 132px;

$primary: var(--palette-primary-main);
$on-primary: #ffffff;
$primary-container: #ecdcff;
$on-primary-container: #270057;

$secondary: var(--palette-secondary-main);
$on-secondary: #ffffff;
$secondary-container: #dce1ff;
$on-secondary-container: #001551;

$tertiary: #7f525c;
$on-tertiary: #ffffff;
$tertiary-container: #ffd9e0;
$on-tertiary-container: #32101a;

$error: var(--palette-error-main);
$on-error: #ffffff;
$error-container: #ffdad6;
$on-error-container: #410002;

$background: var(--palette-background-default);
$on-background: #1d1b1e;
$on-surface: #1d1b1e;
$surface: var(--palette-surface);
$inverse-surface: var(--palette-inverse-surface);
$on-inverse-surface: var(--palette-on-inverse-surface);

$surface-variant: #e8e0eb;
$on-surface-variant: #49454e;
$inverse-on-surface: #f5eff4;

$surface1: var(--palette-surface1);
$surface2: var(--palette-surface2);
$surface3: var(--palette-surface3);
$surface4: var(--palette-surface4);
$surface5: var(--palette-surface5);

$outline: #7b757f;
$outline-variant: #cbc4cf;

$success: #006e2b;

$table-header: #f5f5f5;

$elevation-light-1:
  0px 1px 2px rgba(0, 0, 0, 0.3),
  0px 1px 3px 1px rgba(0, 0, 0, 0.15);
$elevation-light-2:
  0px 1px 2px rgba(0, 0, 0, 0.8),
  0px 1px 8px 1px rgba(0, 0, 0, 0.15);
$elevation-light-3:
  0px 1px 2px rgba(0, 0, 0, 0.11),
  0px 1px 11px 1px rgba(0, 0, 0, 0.15);

$details-panel-header: var(--details-panel-header);
$details-panel-body: var(--details-panel-body);

$brightPurple: #6d3eff;
$brightOrange: #ff5e00;
$lightOrange: #fbdac9;

$alert-success: #c2f4d0;
$alert-info: #dce1ff;
$alert-warning: #ffe3d4;
$alert-error: #ffdad6;
$alert-error-border: #ba1a1a;
