.contentContainer {
  padding: 0 16px;

  .roomRow {
    display: flex;
    align-items: center;
    padding: 8px 0;
    width: 100%;
    gap: 24px;
  }

  .row {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .nameOrganisationUnitContainer {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .unlinkButton {
      padding: 0px 16px;
      min-width: fit-content;
    }

    .disabledIcon {
      opacity: 0.38;
    }
  }

  .clickable {
    cursor: pointer;
    text-decoration: underline;
    text-underline-position: under;
  }
}

.divider {
  padding: 16px 16px 16px 8px;
}
