@use "style/constants.scss" as constants;

.tree-item-container {
  height: 40px;
  min-width: 350px;
  display: flex;
  align-items: center;

  .starting-icons-container {
    display: flex;
    align-items: flex-start;
  }

  .ending-icons-container {
    color: constants.$on-surface-variant;
    display: flex;
    align-items: center;
    margin-right: 12px;
    margin-left: auto;
    gap: 8px;
  }
}
