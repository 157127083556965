@use "style/constants.scss" as constants;

.summary {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .checkbox {
    min-width: 80px;
  }

  .groupContainer {
    display: flex;
    gap: 18px;
    align-items: center;

    .icon {
      color: constants.$primary;
      display: flex;
    }
  }
}