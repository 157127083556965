.contentContainer {
  padding: 0px 16px;

  .switchRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .switchRowText {
      align-self: center;
    }
  }
}

.divider {
  padding: 16px 16px 16px 8px;
}
