@use "style/constants.scss" as constants;

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  .topRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .systemFilters {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      align-items: center;

      .clearFilters {
        display: flex;
        align-items: center;
        padding: 0 12px;
        cursor: pointer;
      }
    }

    .refreshButton svg {
      color: constants.$on-surface;
    }
  }

  .monitoringFilters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
  }
}
