@use "style/constants.scss" as constants;

.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 312px;
  padding: 24px;
  background: constants.$surface3;
  border-radius: 28px;
  word-break: break-word;

  .heading {
    letter-spacing: 1px;
  }

  .content {
    white-space: pre-wrap;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    .button {
      height: 40px;
    }
  }
  .progressIndicatorContainer {
    display: flex;
    justify-content: center;
  }
}

