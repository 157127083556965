.contentContainer {
  padding: 8px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .textFields {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    gap: 8px;

    .roomTextField * {
      word-break: break-word;
    }
  }

  .sliderIcon {
    margin-top: 4px;
  }

  .sliderIconSmall {
    margin-top: 8px;
    width: 14px;
    height: 14px;
  }
}

.divider {
  padding: 16px 16px 16px 8px;
}
