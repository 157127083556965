.container {
  width: 100%;

  .configurationRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
