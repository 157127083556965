@use "style/constants.scss" as constants;

.container {
  display: flex;
  gap: 4px;
  align-items: center;

  .startIconContainer {
    margin-right: 12px;

    svg {
      color: constants.$primary;
      height: 24px;
      width: 24px;
    }
  }

  .chevronRightIcon {
    color: constants.$on-surface-variant;
    height: 16px;
    width: 16px;
  }

  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
