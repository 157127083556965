@use "style/constants.scss" as constants;

.tooltip {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .typography {
    color: constants.$inverse-on-surface;
  }

  .chevronRightIcon {
    color: constants.$inverse-on-surface;
    height: 16px;
    width: 16px;
  }
}
