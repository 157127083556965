@use "style/constants.scss" as constants;

.organisation-details {
  max-height: 100%;
  display: flex;
  flex-direction: column;

  form {
    max-height: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  .body {
    overflow-y: auto;
    overflow-x: hidden;

    border-radius: 0 0 28px 28px;
    padding: 16px 24px 24px 24px;
    margin-bottom: 32px;

    background: constants.$details-panel-body;

    &.is-viewing {
      .organisation-bread-crumb-container {
        padding-top: 8px;
      }
    }

    .organisation-bread-crumb-container {
      padding-top: 40px;
    }

    .diversion-channel-description-container {
      margin-top: 16px;
      padding: 16px;
      display: flex;
      align-items: center;
      gap: 16px;
      background-color: constants.$surface-variant;
      border-radius: 8px;

      svg {
        color: constants.$primary;
      }

      .diversion-channel-description {
        white-space: pre-wrap;
      }
    }

    .organisation-settings-loader {
      height: 86px;
      width: 100%;
    }

    .organisation-settings-container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .diversion-timeout-top-margin {
        margin-top: 16px;
      }
    }
  }

  .MuiFormControl-root {
    min-width: 100%;
  }

  .organisation-units-details-divider {
    padding-top: 56px;
    padding-bottom: 16px;
  }
}
