@use "style/constants.scss" as constants;

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .iconRow {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-right: 8px;
  }
}
