@use "style/constants.scss" as constants;

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  position: sticky;
  top: 0;
  z-index: 100;

  border-radius: 28px 28px 0 0;
  padding: 24px 24px 16px 24px;

  border-bottom: 1px solid rgba(constants.$outline, 0.16);
  background: constants.$details-panel-header;

  .topHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .actionsContainer {
    min-height: 42px;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    gap: 16px;
  }
}
