@use "style/constants.scss" as constants;

.device-details-information-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  .device-title {
    word-break: break-word;
  }

  .monitoring-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .outdated-device-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    align-items: center;

    background-color: constants.$inverse-surface;
    color: constants.$on-inverse-surface;

    .outdated-device-text {
      color: constants.$on-inverse-surface;
    }
  }

  .information-part-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    * {
      word-break: break-word;
    }

    .form-field {
      width: 100%;
    }
  }

  .functional-id-container {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}
