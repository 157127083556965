@use "style/constants.scss" as constants;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  word-break: break-word;
  gap: 32px;

  &.viewing {
    gap: 32px;
  }

  .informationItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    .textFormat {
      white-space: pre-line;
      word-break: break-word;
    }
  }

  .linkButton {
    cursor: pointer;
    text-decoration: underline;
    text-underline-position: under;
  }
}
