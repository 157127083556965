@use "style/constants.scss" as constants;

.container {
  max-height: 100%;
  display: flex;
  flex-direction: column;

  form {
    max-height: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;
  }

  .body {
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0 0 28px 28px;
    padding: 16px 24px 24px 24px;
    margin-bottom: 32px;
    background: constants.$details-panel-body;
  }

  .MuiFormControl-root {
    min-width: 100%;
  }

  .summaryContent {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 16px 0px;
    align-items: center;
    width: 100%;
  }
}
